.navigation-navbar {
    position: fixed;
    /*El sticky-top tiene z-index de 1020*/
    z-index: 1021;
    background: #36404a;
    width: 100%;
    height: 60px;
}

.side-bar-header {
    background: #36404a;
    width: 100%;
    height: 60px;
}