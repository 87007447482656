.login-background {
    width: 100%;
    min-height: 100vh;
    background: url(images/agsquare.png);
}

.login-box {
    position: fixed;
    background: #ffffff;
    width: 90%;
    max-width: 420px;
    border-radius: 5px;
    border: 1px solid rgba(54, 64, 74, 0.1);
    margin-top: 5%;
}

.login-image{
    width: 132px;
}