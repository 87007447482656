html,
body {
    margin: 0;
}

@font-face {
    font-family: "Noto Sans JP", sans-serif;
    src: url("./fonts/NotoSansJP-Regular.otf");
}

@font-face {
    font-family: "Source Sans Pro", sans-serif;
    src: url("./fonts/SourceSansPro-Regular.ttf");
}

/*Global Colors*/

:root {
    --main-green-color: #5fbeaa;
    --main-red-color: #d0342c;
    --main-gray-color: #979797;
}

/*Global font styles*/

.admin-muted-text {
    font-family: "Noto Sans JP", sans-serif;
    line-height: 1.6;
    font-weight: 400;
    color: #98a6ad;
    font-size: 15;
}

.admin-muted-text-small {
    font-family: "Noto Sans JP", sans-serif;
    line-height: 1.6;
    font-weight: 400;
    color: #98a6ad;
    font-size: 12px;
}

.admin-page-tilte {
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    letter-spacing: 0.04em;
    font-size: 18px;
    line-height: 16px;
    color: #505458;
    font-weight: 600;
}

.admin-main-title-gray {
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    letter-spacing: 0.04em;
    font-size: 13px;
    line-height: 16px;
    color: #797979;
    font-weight: 600;
    text-transform: uppercase;
}

.admin-main-title-dark-gray {
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    letter-spacing: 0.04em;
    font-size: 13px;
    line-height: 16px;
    color: #505458;
    font-weight: 600;
    text-transform: uppercase;
}

.admin-main-title-green {
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    letter-spacing: 0.04em;
    font-size: 17px;
    line-height: 16px;
    color: var(--main-green-color);
    font-weight: 600;
}

.sidebar-item-text {
    font-family: "Noto Sans JP", sans-serif;
    font-weight: normal;
    text-decoration: none;
    line-height: 1.3px;
    font-weight: 400;
    color: #75798b;
    font-size: 14;
}

.footer-text {
    font-family: "Noto Sans JP", sans-serif;
    line-height: 1.42em;
    word-spacing: normal;
    font-weight: 400;
    color: #58666e;
    font-size: 13;
}

.admin-label-text {
    font-family: "Noto Sans JP", sans-serif;
    color: #797979;
    font-size: 14px;
    line-height: 20px;
    word-spacing: normal;
    font-weight: 600;
}

.admin-simple-text {
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    letter-spacing: 0.04em;
    font-size: 14px;
    line-height: 16px;
    color: #797979;
    font-weight: 500;
}

/* Global Containers*/

.navbar-replacement {
    width: 100%;
    height: 60px;
}

.content-white-box {
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid rgba(54, 64, 74, 0.1);
}

.dropdown-overlay-background {
    background-color: #fff;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.icon-with-text-left {
    flex-grow: 1;
    background-color: #ffffff;
    box-sizing: border-box;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.icon-with-text-font {
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    letter-spacing: 0.04em;
    font-size: 24px;
    line-height: 30px;
    color: #797979;
    font-weight: 600;
}

.icon-with-text-right {
    background-color: #f8fbfc;
    box-sizing: border-box;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 30%;
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Gloabal buttons */

.clear-button {
    border: none;
    padding: none;
    background: none;
}

/*Custom Scrollbar*/

.custom-scrollbar {
    overflow-y: auto;
}

.custom-scrollbar {
    scrollbar-width: thin;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #ededed;
    border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #ededed;
}

.custom-scrollbar ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px #f0f0f0;
}

/* Custom MDB css*/

.pagination .page-item.active .page-link {
    background-color: var(--main-green-color);
}

div.dataTables_wrapper
    div.dataTables_paginate
    ul.pagination
    .page-item.active
    .page-link:focus {
    background-color: var(--main-green-color);
}

.pagination .page-item.active .page-link:hover {
    background-color: var(--main-green-color);
}

td {
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/*Custom  SweetAlert css*/

.swal-footer {
    text-align: center;
}

/* Custom ReactDraft css*/

.rdw-editor-main {
    min-height: 350px;
    max-height: 600px;
    border: 1px solid #f1f1f1;
}
